const actions = {
    LOADING_START       : 'LOADING_START',
    LOADING_END         : 'LOADING_END',

    FETCH_STUDENT_ACADEMIC_INFO       : 'FETCH_STUDENT_ACADEMIC_INFO',
    FETCH_ACADEMIC_INFO       : 'FETCH_ACADEMIC_INFO',
    FETCH_FILE_SIZE       : 'FETCH_FILE_SIZE',
    FETCH_FILE_LENGTH       : 'FETCH_FILE_LENGTH',

    FETCH_UTILITIES_ROLES       : 'FETCH_UTILITIES_ROLES',
    FETCH_UTILITIES_USER        : 'FETCH_UTILITIES_USER',
    FETCH_UTILITIES_SESSIONS    : 'FETCH_UTILITIES_SESSIONS',
    FETCH_UTILITIES_CLASSES     : 'FETCH_UTILITIES_CLASSES',
    FETCH_UTILITIES_SECTIONS     : 'FETCH_UTILITIES_SECTIONS',
    FETCH_UTILITIES_CLASSROOMS     : 'FETCH_UTILITIES_CLASSROOMS',
    FETCH_UTILITIES_PERIODS     : 'FETCH_UTILITIES_PERIODS',
    FETCH_UTILITIES_SUBJECTS     : 'FETCH_UTILITIES_SUBJECTS',
    FETCH_UTILITIES_ALL_SUBJECTS     : 'FETCH_UTILITIES_ALL_SUBJECTS',
    FETCH_UTILITIES_USERS       : 'FETCH_UTILITIES_USERS',
    FETCH_UTILITIES_HODS       : 'FETCH_UTILITIES_HODS',
    FETCH_UTILITIES_STUDENTS    : 'FETCH_UTILITIES_STUDENTS',
    FETCH_UTILITIES_COORDINATED_STUDENTS    : 'FETCH_UTILITIES_COORDINATED_STUDENTS',
    FETCH_UTILITIES_ROUTINES    : 'FETCH_UTILITIES_ROUTINES',
    FETCH_UTILITIES_SESSION    : 'FETCH_UTILITIES_SESSION',
    FETCH_UTILITIES_CANDIDATES    : 'FETCH_UTILITIES_CANDIDATES',
    FETCH_UTILITIES_MESSAGE_CANDIDATES    : 'FETCH_UTILITIES_MESSAGE_CANDIDATES',
    FETCH_UTILITIES_COMMENT_CANDIDATES    : 'FETCH_UTILITIES_COMMENT_CANDIDATES',
    RESET_UTILITY_ROUTINES      : 'RESET_UTILITY_ROUTINES',
    FETCH_SECTION_ROUTINES      : 'FETCH_SECTION_ROUTINES',
    FETCH_SECTION_PERIODS      : 'FETCH_SECTION_PERIODS',
    FETCH_UTILITY_CRITERIA      : 'FETCH_UTILITY_CRITERIA',
    FETCH_UTILITY_POSITIONS      : 'FETCH_UTILITY_POSITIONS',

    LoadingStart: () => {
        return {
            type: actions.LOADING_START,
        };
    },
    LoadingEnd: data => {
        return {
            type: actions.LOADING_END
        };
    },
    fetchStudentAcademicInfoAction: (data) => {
        return {
            type: actions.FETCH_STUDENT_ACADEMIC_INFO,
            data: data
        };
    },
    fetchAcademicInfoAction: (data) => {
        return {
            type: actions.FETCH_ACADEMIC_INFO,
            data: data
        };
    },
    fetchRolesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROLES,
            data: data
        };
    },
    fetchUserAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USER,
            data: data
        };
    },
    fetchSessionsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SESSIONS,
            data: data
        };
    },
    fetchSessionAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SESSION,
            data: data
        };
    },
    fetchCriteriaAction: (data) => {
        return {
            type: actions.FETCH_UTILITY_CRITERIA,
            data: data
        };
    },
    fetchPositionsAction: (data) => {
        return {
            type: actions.FETCH_UTILITY_POSITIONS,
            data: data
        };
    },
    fetchClassesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CLASSES,
            data: data
        };
    },
    fetchSectionsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SECTIONS,
            data: data
        };
    },
    fetchClassRoomsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CLASSROOMS,
            data: data
        };
    },
    fetchPeriodsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PERIODS,
            data: data
        };
    },
    fetchSectionPeriodsAction: (data) => {
        return {
            type: actions.FETCH_SECTION_PERIODS,
            data: data
        };
    },
    fetchSubjectsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SUBJECTS,
            data: data
        };
    },
    fetchAllSubjectsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ALL_SUBJECTS,
            data: data
        };
    },
    fetchFileSizeAction: (data) => {
        return {
            type: actions.FETCH_FILE_SIZE,
            data: data
        };
    },
    fetchFileLengthAction: (data) => {
        return {
            type: actions.FETCH_FILE_LENGTH,
            data: data
        };
    },
    fetchUsersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USERS,
            data: data
        };
    },
    fetchCandidatesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CANDIDATES,
            data: data
        };
    },
    fetchMessageCandidatesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_MESSAGE_CANDIDATES,
            data: data
        };
    },
    fetchCommentCandidatesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_COMMENT_CANDIDATES,
            data: data
        };
    },
    fetchHodsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_HODS,
            data: data
        };
    },
    fetchStudentsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_STUDENTS,
            data: data
        };
    },
    fetchCoordinatedStudentsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_COORDINATED_STUDENTS,
            data: data
        };
    },
    fetchRoutinesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROUTINES,
            data: data
        };
    },
    fetchSectionRoutinesAction: (data) => {
        return {
            type: actions.FETCH_SECTION_ROUTINES,
            data: data
        };
    },
    resetRoutinesFormAction: () => {
        return {
            type: actions.RESET_UTILITY_ROUTINES,
            data: []
        } ;
    },
};

export default actions;
