export class Constants {

   // local Server
   //  static BASE_ENDPOINT    = 'http://localhost:3333/';
   //  static BASE_MEDIA_URL    = 'http://localhost:3333/';

    // Live-1 Server For Roney
    // static BASE_ENDPOINT    = 'https://api.roneykhan.online/';
    // static BASE_MEDIA_URL    = 'https://api.roneykhan.online/';

    // Live-2 Server for HURDCO
    static BASE_ENDPOINT    = 'https://office-api.hurdcointschool.com/';
    static BASE_MEDIA_URL    = 'https://office-api.hurdcointschool.com/';

    static CLIENT_ID        = 'demo-client';
    static CLIENT_SECRET    = 'demo-secret';
    // static BASE_MEDIA_URL    = 'http://3.110.202.185:3333/';
    // static BASE_ENDPOINT    = 'http://3.110.202.185:3333/';

    static STORAGE_ACCESS_TOKEN     = 'accessToken';
    static STORAGE_REFRESH_TOKEN    = 'refreshToken';
    static STORAGE_USER_INFO        = 'userInfo';
    static STORAGE_ACADEMIC_INFO        = 'academicInfo';
    static STORAGE_USER_SCOPES      = 'userScopes';
    static STORAGE_USER_LOGGED_IN   = 'userLoggedIn';


    static S3_DIR_NAME = 'student';
    static S3_USER_NAME = 'user';

    // Direct Upload HURDCO
    // static S3_BUCKET_NAME = 'direct-upload-hurdco';
    // static S3_REGION = 'us-east-1';
    // static S3_ACCESS_KEY_ID = 'AKIAY554DOVWAXR2WX6R';
    // static S3_ACCESS_KEY_SECRET = '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';
    // static S3_BASE_URL = 'https://direct-upload-hurdco.s3.amazonaws.com/';

    // Singapore Upload HURDCO
    static S3_BUCKET_NAME = 'sing-hurdco';
    static S3_REGION = 'ap-southeast-1';
    static S3_ACCESS_KEY_ID = 'AKIAY554DOVWB4DZ62OH';
    static S3_ACCESS_KEY_SECRET = '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';
    static S3_BASE_URL = 'https://sing-hurdco.s3.amazonaws.com/';

    static S3_NEWS_EVENTS = "news-events"

    static S3_ACADEMIC_NAME = 'academic';
    static ACADEMIC_NOTES = (key) => `${this.S3_ACADEMIC_NAME}/photo${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static ACADEMIC_NOTICES = (key) => `${this.S3_ACADEMIC_NAME}/photo${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;

    static S3_FILE_NAME = (key) => `${+ new Date()}-${key.replace(/[ ,.]/g, "-")}`;
    static TEACHER_PHOTO = (key) => `${this.S3_USER_NAME}/photo${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static STUDENT_PHOTO = (key) => `${this.S3_DIR_NAME}/photo${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static FATHER_PHOTO = (key) => `${this.S3_DIR_NAME}/parents/father${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static MOTHER_PHOTO = (key) => `${this.S3_DIR_NAME}/parents/mother${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static OTHER_ONE_PHOTO = (key) => `${this.S3_DIR_NAME}/other/other1${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static OTHER_TWO_PHOTO = (key) => `${this.S3_DIR_NAME}/other/other2${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    static OTHER_THREE_PHOTO = (key) => `${this.S3_DIR_NAME}/other/other3${key ? `/${key.replace(/[ ,.]/g, "-")}` : ""}`;
    // static S3_BASE_URL = (key) => `https://roneykhan202.s3.us-east-1.amazonaws.com${key}`;



    static AUTH         = this.BASE_ENDPOINT + 'back-end/auth/';
    static UTILITIES    = this.BASE_ENDPOINT + 'back-end/utilities/';

    static USER_MANAGEMENT  = this.BASE_ENDPOINT + 'back-end/user-management/';
    static KPI  = this.BASE_ENDPOINT + 'back-end/kpi/';
    static STUDENTS         = this.BASE_ENDPOINT + 'back-end/all-students/';
    static STUDENTS_ACCESS         = this.BASE_ENDPOINT + 'back-end/student-access/';
    static ACADEMIC         = this.BASE_ENDPOINT + 'back-end/academic/';
    static DASHBOARD         = this.BASE_ENDPOINT + 'back-end/dashboard/';
    static INVOICE         = this.BASE_ENDPOINT + 'back-end/invoice/';
    static MESSAGE         = this.BASE_ENDPOINT + 'back-end/message/';
    static SCHEDULE         = this.BASE_ENDPOINT + 'back-end/schedule/';
    static CAREER         = this.BASE_ENDPOINT + 'back-end/career/';
    static BLOGS         = this.BASE_ENDPOINT + 'back-end/blogs/';

    static SCHOOL_NAME = "HURDCO International School"
    static SCHOOL_FIRSTNAME = "HURDCO"
    static SCHOOL_LASTNAME = "International School"

    static WEEKDAYS = [
        {label: "Weekday", value: ""},
        {label: "Saturday", value: "Saturday"},
        {label: "Sunday", value: "Sunday"},
        {label: "Monday", value: "Monday"},
        {label: "Tuesday", value: "Tuesday"},
        {label: "Wednesday", value: "Wednesday"},
        {label: "Thursday", value: "Thursday"},
        {label: "Friday", value: "Friday"}
    ];

    static SECTION = [
        {label: "Preschool", value: "preschool"},
        {label: "Middle School", value: "middleSchool"},
        {label: "High School", value: "highSchool"},
        {label: "Admin", value: "admin"},
        {label: "All", value: "all"},
        {label: "Others", value: "others"},
    ];

    static TEACHER_SECTION = [
        {label: "Preschool", value: "preschool"},
        {label: "Middle School", value: "middleSchool"},
        {label: "High School", value: "highSchool"},
    ];

    static ROLES = [
        {label: "Teacher", value: "teacher"},
        {label: "Coordinator", value: "coordinator"},
        {label: "Asst. Coordinator", value: "AsstCoordinator"},
        {label: "Head of the Dept.", value: "HOD"},
        {label: "Grade In-charge", value: "GradeInCharge"},
        {label: "Vice Principal", value: "vicePrincipal"},
        {label: "Principal", value: "principal"},
        {label: "Admin Staff", value: "adminStaff"},
        {label: "System Administrator", value: "systemAdministrator"},
    ];

    static GRADE_SUBJECT_LENGTH = {
        I: "8",
        II: "8",
        III: "10",
        IV: "10",
        V: "10",
        VI: "12",
        VII: "12",
        VIII: "13",
        IX: ["A", "B", "C", "D", "E"],
        X: ["A", "B", "C", "D", "E"],
        XI: ["A", "B"],
        XII: ["A", "B"],
    }

    static REMARKS = {
        feedBackSegment : "Class Teacher's Feedback",
        curricularSegment : "Co-curricular Activity",
        achievementSegment : "Achievement",
        PERSONAL_SOCIAL_DEVELOPMENT : "PERSONAL/SOCIAL DEVELOPMENT",
        PHYSICAL_DEVELOPMENT : "PHYSICAL DEVELOPMENT",
        MUSIC : "MUSIC",
        DANCE : "DANCE",
        LETTER : "LETTER",
        NUMBER_WORK : "NUMBER WORK",
        ART : "ART",
        CONCEPT : "CONCEPT",
        LANGUAGE_BANGLA: "LANGUAGE BANGLA",
        LANGUAGE_ENGLISH: "LANGUAGE ENGLISH",
        MATHEMATICS: "MATHEMATICS",
        SCIENCE: "SCIENCE",
        ENGLISH_LITERATURE: "ENGLISH LITERATURE",
        TECHNOLOGY: "TECHNOLOGY",
        COMMENTS:"COMMENTS",
        TEACHER_REPORT:"TEACHER_REPORT",
    }

    static REMARKS_VALUE_TYPES = [
        {label: "Select", value: ""},
        {label: "Class Teacher's Feedback", value: "Class Teacher's Feedback"},
        {label: "Co-curricular Activity", value: "Co-curricular Activity"},
        {label: "Achievement", value: "Achievement"},
        {label: "PERSONAL/SOCIAL DEVELOPMENT", value: "PERSONAL/SOCIAL DEVELOPMENT"},
        {label: "PHYSICAL DEVELOPMENT", value: "PHYSICAL DEVELOPMENT"},
        {label: "MUSIC", value: "MUSIC"},
        {label: "DANCE", value: "DANCE"},
        {label: "LETTER", value: "LETTER"},
        {label: "NUMBER WORK", value: "NUMBER WORK"},
        {label: "ART", value: "ART"},
        {label: "CONCEPT", value: "CONCEPT"},
        {label: "LANGUAGE BANGLA", value: "LANGUAGE BANGLA"},
        {label: "LANGUAGE ENGLISH", value: "LANGUAGE ENGLISH"},
        {label: "MATHEMATICS", value: "MATHEMATICS"},
        {label: "SCIENCE", value: "SCIENCE"},
        {label: "ENGLISH LITERATURE", value: "ENGLISH LITERATURE"},
        {label: "TECHNOLOGY", value: "TECHNOLOGY"},
        {label: "COMMENTS", value: "COMMENTS"},
        {label: "TEACHER_REPORT", value: "TEACHER_REPORT"},
    ]

    static STATUS = [
        {label: "Status", value: ""},
        {label: "Available", value: "active"},
        {label: "Unavailable", value: "inactive"},
    ];
    static SCHEDULE_STATUS = [
        {label: "Status", value: ""},
        {label: "Active", value: "active"},
        {label: "Inactive", value: "inactive"},
    ];

    static ADMISSION_STATUS = [
        {label: "Select", value: ""},
        {label: "Admitted", value: "admitted"},
        {label: "Not Admitted", value: "notAdmitted"},
        {label: "Left", value: "left"},
    ];

    static PROMOTIONAL_STATUS = [
        {label: "Select", value: ""},
        {label: "Promoted", value: "promoted"},
        {label: "Not Promoted", value: "notPromoted"},
    ];

    static MID_HI_REMARKS_VALUE = [
        {label: "Select", value: ""},
        {label: "Always", value: "Always"},
        {label: "Mostly", value: "Mostly"},
        {label: "Usually", value: "Usually"},
        {label: "Occasionally", value: "Occasionally"},
        {label: "Rarely", value: "Rarely"},
    ];

    static PRE_REMARKS_VALUE = [
        {label: "Select", value: ""},
        {label: "Excellent", value: "Excellent"},
        {label: "Satisfactory", value: "Satisfactory"},
        {label: "Improvement", value: "Improvement"},
        {label: "Needs Improvement", value: "Needs Improvement"},

    ];

    static GENDER = [
        {label: "Select", value: ""},
        {label: "Male", value: "male"},
        {label: "Female", value: "female"},
        {label: "Other", value: "other"},
    ];

    static SECTION_STATUS = [
        {label: "Select", value: ""},
        {label: "Preschool", value: "preschool"},
        {label: "Middle School", value: "middleSchool"},
        {label: "High School", value: "highSchool"},
    ];

    static Preschool_Section_Name = [
        {Name: "MARS"},
        {Name: "NEPTUNE"},
        {Name: "SATURN"},
        {Name: "JUPITER"},
    ];

    static Pre_New_Section_Name = {
        MARS: "MARS",
        NEPTUNE: "NEPTUNE",
        SATURN: "SATURN",
        JUPITER: "JUPITER"
    }

    static M_H_Section_Name = [
        {Name: "A"},
        {Name: "B"},
        {Name: "C"},
        {Name: "D"},
        {Name: "E"},
    ];

    static RIBBON_COLOR = [
        {label: "Not Provided", value: "not_provided"},
        {label: "Red", value: "red"},
        {label: "Green", value: "green"},
        {label: "Blue", value: "blue"},
    ];

    static BOOLEAN = [
        {label: "Yes", value: true},
        {label: "No", value: false},
    ];

    static PRESCHOOL_GRADE = [
        {label: "PLAY GROUP"},
        {label: "NURSERY"},
        {label: "KG-I"},
        {label: "KG-II"}
    ]

    static SECTION_ORDER = {
        "PLAY GROUP": ["MARS", "NEPTUNE", "SATURN", "JUPITER"],
        NURSERY: ["MARS", "NEPTUNE", "SATURN", "JUPITER"],
        "KG-I": ["MARS", "NEPTUNE", "SATURN", "JUPITER"],
        "KG-II": ["MARS", "NEPTUNE", "SATURN", "JUPITER"],
        I: ["A", "B", "C", "D", "E"],
        II: ["A", "B", "C", "D", "E"],
        III: ["A", "B", "C", "D", "E"],
        IV: ["A", "B", "C", "D", "E"],
        V: ["A", "B", "C", "D", "E"],
        VI: ["A", "B", "C", "D", "E"],
        VII: ["A", "B", "C", "D", "E"],
        VIII: ["A", "B", "C", "D", "E"],
        IX: ["A", "B", "C", "D", "E"],
        X: ["A", "B", "C", "D", "E"],
        XI: ["A", "B"],
        XII: ["A", "B"],
    }

    static IDENTITIES = [
        {label: "Select", value: ""},
        {label: "Birth Certificate", value: "birthCertificate"},
        {label: "Passport", value: "passport"},
        {label: "NID", value: "nid"},
    ];

    static MOCK_TYPES = [
        {label: "Select", value: ""},
        {label: "Qualifying Mock", value: "qualifyingMock"},
        {label: "Quarterly Mock", value: "quarterlyMock"},
        {label: "Half Yearly Mock", value: "halfYearlyMock"},
        {label: "First Mock", value: "firstMock"},
        {label: "Second Mock", value: "secondMock"},
    ];

    static QUARTER_TYPES = [
        {label: "Select", value: ""},
        {label: "First Quarter", value: "firstQuarter"},
        {label: "Second Quarter", value: "secondQuarter"},
        {label: "Third Quarter", value: "thirdQuarter"},
        {label: "Fourth Quarter", value: "fourthQuarter"},
    ];

    static desiredMiddleOrder = [
        "Mathematics",
        "English Language",
        "English Literature",
        "Bangla",
        "Bangla Language",
        "Bangla Literature",
        "Science",
        "Computer Science",
        "Religious Studies/Moral Science",
        "History/Social Studies",
        "Geography/Social Studies",
        "Social Studies",
        "Geography",
    ];

    static middleSchoolGradesOnly = [
        "Physical Education",
        "Library/Reading Class",
        "Art & Design",
        "Music",
    ]

    static desiredHigSchoolOrder = [
        "English Language",
        "English Literature",
        "Bengali Language",
        "Bengali Literature",
        "Mathematics",
        "Physics",
        "Chemistry",
        "Biology",
        "Computer Science",
        "Bangladesh Studies",
        "Combined Business Studies",
        "Art & Design",
        "Physical Education"
    ];

    static desiredMockOrder = [
        "English Language 1123",
        "Bengali 3204",
        "Mathematics D 4024",
        "Additional Mathematics 4037",
        "Physics 5054",
        "Chemistry 5070",
        "Biology 5090",
        "Accounting 7707",
        "Business Studies 7115",
        "Economics 2281",
        "Computer Science 2210",
        "Bangladesh Studies 7094",
        "Art & Design 6090",
        "Physical Education",
    ];

    static feedBackOrdered = [
        "Complies with school rules",
        "Shows good behaviour and attitude",
        "Sincere about attire",
        "Maintains good peer relation",
        "Punctual",
    ]

    static ORDERED_LIST_PERSONAL_SOCIAL_DEVELOPMENT = [
        "Listens attentively",
        "Listens to others without interruption",
        "Follows directions",
        "Works without constant supervision",
        "Completes tasks",
        "Takes care of materials/ Own belongings",
        "Expresses self-creativity",
        "Observes, questions, explores",
        "Takes care of personal needs",
        "Takes pride on his/her accomplishments",
        "Uses appropriate manners",
        "Shows positive attitude",
        "Gets along with other children",
        "Participates in group activities",
        "Expresses self-control",
        "Respects rights and properties of others",
        "Accepts Corrections"
    ];
    static ORDERED_LIST_PHYSICAL_DEVELOPMENT = [
        "Shows small muscle control (painting, Colouring, writing, drawing, cutting, assembling puzzles etc.)",
        "Physical Education Skills = shows large muscle control (runs, jumps, throws, hopes, catches)",
        "Body Coordination",
        "Physical Education Conducts"
    ];

    static ORDERED_LIST_MUSIC = [
        "Enthusiasm",
        "Melody",
        "Lyric",
        "Rhythm"
    ];

    static ORDERED_LIST_TEACHER_REPORT = [
        "Uniform",
        "Cleanliness / Hygiene",
        "Punctual",
    ];

    static ORDERED_LIST_TECHNOLOGY = [
        "Computer Skills",
    ];

    static ORDERED_LIST_DANCE = [
        "Music & Movement",
        "Use of Props",
        "Body Coordination",
        "Performance",
        "Enthusiasm"
    ];

    static ORDERED_LIST_LETTER = [
        "English",
        "I listen attentively",
        "I respond to questions",
        "I pronounce words correctly",
        "I can identify letters and relates to their sounds",
        "I write independently",
        "I participate in class discussions"
    ];

    static ORDERED_LIST_NUMBER_WORK = [
        "Listens attentively to the number called out",
        "Understands and recognizes number",
        "Can write independently",
        "Conceptual Fluency (missing, counting, sorting and after)",
        "Can add small numbers"
    ];

    static ORDERED_LIST_ART = [
        "I can recognize lines",
        "I can identify colours",
        "I manage colouring with in the border line",
        "Folding paper",
        "Pasting paper",
        "Uses materials appropriately",
        "Demonstrates creativity",
        "Open-minded",
        "Self-management skills",
        "Uses materials and techniques appropriately"
    ];

    static ORDERED_LIST_CONCEPT = [
        "Listens attentively to the topic taught",
        "Understands concept",
        "Participates in class discussions",
        "Shows interest and enjoys topic",
        "Expresses ideas clearly",
        "Links topics to daily life events"
    ];

    static ORDERED_LIST_LANGUAGE_BANGLA = [
        "Listening",
        "Speaking",
        "Viewing",
        "Presenting",
        "Reading",
        "Writing"
    ];

    static ORDERED_LIST_LANGUAGE_ENGLISH = [
        "Listening",
        "Speaking",
        "Viewing",
        "Presenting",
        "Reading",
        "Writing"
    ];

    static ORDERED_LIST_LANGUAGE_MATHEMATICS = [
        "Measurement/ Shape/ Space/ Place value",
        "Conceptual Fluency (Number sequence, missing numbers, before/between/after number, skip counting)",
        "Reasoning (Individual/collective/addition/subtraction, ordinal/cardinal, odd/even)",
        "Viewing",
        "Presenting",
        "Reading",
        "Writing"
    ];

    static ORDERED_LIST_SCIENCE = [
        "Animals (Life Cycle)",
        "Ecology",
        "Matter",
        "Atmosphere and Weather",
        "Space",
        "Computer Skills"
    ];

    static ORDERED_LIST_LANGUAGE_ENGLISH_LITERATURE = [
        "Phonetic Awareness",
        "Awareness of Print",
        "Vocabulary/Spelling",
        "Segmenting/ Blending word",
        "Reading Comprehension"
    ];

    static ATTENDANCE_TYPE = [
        {label: "Select", value: ""},
        {label: "First Quarter", value: "firstQuarter"},
        {label: "Second Quarter", value: "secondQuarter"},
        {label: "Third Quarter", value: "thirdQuarter"},
        {label: "Fourth Quarter", value: "fourthQuarter"},
        {label: "Half Yearly", value: "halfYearly"},
        {label: "Final", value: "final"},
        {label: "Qualifying Mock", value: "qualifyingMock"},
        {label: "Half Yearly Mock", value: "halfYearlyMock"},
        {label: "Quarterly Mock", value: "quarterlyMock"},
        {label: "First Mock", value: "firstMock"},
        {label: "Second Mock", value: "secondMock"},
    ]

    static EXAM_TYPES = [
        {label: "Select", value: ""},
        {label: "Mock", value: "mock"},
        {label: "Quarter", value: "quarter"},
        {label: "Half Yearly", value: "halfYearly"},
        {label: "Final", value: "final"},
    ];
    static PAYMENT_TYPES = [
        {label: "Admission Fees (New Student)", value: "newStudentAdmissionFees", payment_method: "yearly"},
        {label: "Caution Money (Once & Refundable)", value: "cautionFees", payment_method: "yearly"},
        {label: "Computer Fees (New Students)", value: "newComputerFees", payment_method: "yearly"},

        {label: "Computer Fees (Old Students)", value: "oldComputerFees", payment_method: "yearly"},

        {label: "Pay Book Fees", value: "payBookFees", payment_method: "yearly"},
        {label: "Exam Fees", value: "examFees", payment_method: "yearly"},
        {label: "Activity Fees", value: "activityFees", payment_method: "yearly"},
        {label: "Diary", value: "diaryFees", payment_method: "yearly"},
        {label: "Planner", value: "plannerFees", payment_method: "yearly"},
        {label: "Books", value: "booksFees", payment_method: "yearly"},
        {label: "Stationary", value: "stationaryFees", payment_method: "yearly"},
        {label: "Copies", value: "copiesFees", payment_method: "yearly"},

        {label: "Tuition Fees", value: "tuitionFees", payment_method: "monthly"},
        {label: "Late Fees", value: "lateFees", payment_method: "monthly"},

        {label: "Physics Lab Fees", value: "physicsLabFees", payment_method: "occasionally"},
        {label: "Chemistry Lab Fees", value: "chemistryLabFees", payment_method: "occasionally"},
        {label: "Biology Lab Fees", value: "biologyLabFees", payment_method: "occasionally"},

        {label: "ICT Lab Fees", value: "ictLabFees", payment_method: "occasionally"},

        {label: "Transport Fees", value: "transportFees", payment_method: "occasionally"},

    ]
    static PAYMENT_RECEIVED_TYPES = [
        {label: "New Student Admission Fees", value: "newStudentAdmissionFees", payment_method: "yearly"},
        {label: "Caution Money (Once & Refundable)", value: "cautionFees", payment_method: "yearly"},
        {label: "Computer Fees (New Students)", value: "newComputerFees", payment_method: "yearly"},

        {label: "Computer Fees (Old Students)", value: "oldComputerFees", payment_method: "yearly"},

        {label: "Pay Book Fees", value: "payBookFees", payment_method: "yearly"},
        {label: "Exam Fees", value: "examFees", payment_method: "yearly"},
        {label: "Activity Fees", value: "activityFees", payment_method: "yearly"},
        {label: "Diary", value: "diaryFees", payment_method: "yearly"},
        {label: "Planner", value: "plannerFees", payment_method: "yearly"},
        {label: "Books", value: "booksFees", payment_method: "yearly"},
        {label: "Stationary", value: "stationaryFees", payment_method: "yearly"},
        {label: "Copies", value: "copiesFees", payment_method: "yearly"},

        {label: "Tuition Fees", value: "tuitionFees", payment_method: "monthly"},

        {label: "Physics Lab Fees", value: "physicsLabFees", payment_method: "occasionally"},
        {label: "Chemistry Lab Fees", value: "chemistryLabFees", payment_method: "occasionally"},
        {label: "Biology Lab Fees", value: "biologyLabFees", payment_method: "occasionally"},

        {label: "ICT/Computer Lab Fees", value: "computerLabFees", payment_method: "occasionally"},

        {label: "Transport Fees", value: "transportFees", payment_method: "occasionally"},
    ]

    static PAYMENT_ENUMS = {
        year: "yearly",
        month: "monthly",
        occasion: "occasionally",
        lateFee: "lateFees",
        noFine: "noFine",
        debit:"debit",
        credit: "credit"
    }

    static PAYMENT_OPTION = [
        {label: "Yearly", value: "yearly"},
        {label: "Monthly", value: "monthly"},
        {label: "Occasionally", value: "occasionally"},
    ]

    static DUE_STATUS = [
        {label: "Partial Late Fee", value: "partialFine"},
        {label: "Complete Late Fee", value: "completeFine"},
    ]

    static PAYMENT_MONTH = [
        {label: "January", value: "january"},
        {label: "February", value: "february"},
        {label: "March", value: "march"},
        {label: "April", value: "april"},
        {label: "May", value: "may"},
        {label: "June", value: "june"},
        {label: "July", value: "july"},
        {label: "August", value: "august"},
        {label: "September", value: "september"},
        {label: "October", value: "october"},
        {label: "November", value: "november"},
        {label: "December", value: "december"},
    ]

    static GRADES_TYPES = [
        {label: "A*", value: "A*"},
        {label: "A", value: "A"},
        {label: "B", value: "B"},
        {label: "C", value: "C"},
        {label: "D", value: "D"},
        {label: "F", value: "F"},
        ]

    static REMARKS_TYPES = [
        {sl: "1", label: "Poor sense of spelling, punctuation and grammar.", value: "Poor sense of spelling, punctuation and grammar."},
        {sl: "2", label: "Incapable of answering analytical question.", value: "Incapable of answering analytical question."},
        {sl: "3", label: "Too talkative and disturbing", value: "Too talkative and disturbing"},
        {sl: "4", label: "Weak in expression", value: "Weak in expression"},
        {sl: "5", label: "Needs to read widely", value: "Needs to read widely"},
        {sl: "6", label: "Should strive hard to excel", value: "Should strive hard to excel"},
        {sl: "7", label: "Handwriting needs improvement.", value: "Handwriting needs improvement"},
        {sl: "8", label: "Irregular in attendance", value: "Irregular in attendance"},
        {sl: "9", label: "Irregular in submission of H.W.", value: "Irregular in submission of H.W."},
        {sl: "10", label: "Inconsistent in task commitment", value: "Inconsistent in task commitment"},
        {sl: "11", label: "Insufficient illustration", value: "Insufficient illustration"},
        {sl: "12", label: "Poor organizational skills", value: "Poor organizational skills"},
        {sl: "13", label: "Did not practise the given sums regularly", value: "Did not practise the given sums regularly"},
        {sl: "14", label: "Capable of doing much better", value: "Capable of doing much better"},
        {sl: "15", label: "Needs improvement", value: "Needs improvement"},
        {sl: "16", label: "Excellent", value: "Excellent"},
        {sl: "17", label: "Very Good", value: "Very Good"},
        {sl: "18", label: "Good", value: "Good"},
        {sl: "19", label: "Good-could do much better", value: "Good-could do much better"},
        {sl: "20", label: "Has shown an improvement", value: "Has shown an improvement"},
        {sl: "21", label: "Late Admission", value: "Late Admission"},
        {sl: "22", label: "Absent", value: "Absent"},
    ];

    static GROUP_TYPES = [
        {label: "Select", value: ""},
        {label: "Holiday", value: "holiday"},
        {label: "1st Quarter", value: "firstQuarter"},
        {label: "2nd Quarter And Half-Yearly Examination", value: "secondQuarterAndHalfYearlyExamination"},
        {label: "3rd Quarter", value: "Third Quarter"},
        {label: "4th Quarter And Final Examination", value: "fourthQuarterAndFinalExamination"},
    ];

    static COLOR_TYPES = [
        {label: "Select", value: ""},
        {label: "Default", value: "default"},
        {label: "Red", value: "red"},
        {label: "Purple", value: "purple"},
    ];
}
