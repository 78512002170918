import actions from './actions';

const {
    LOADING_START, LOADING_END, FETCH_ACADEMIC_INFO, FETCH_FILE_SIZE, FETCH_FILE_LENGTH, FETCH_STUDENT_ACADEMIC_INFO,
    FETCH_UTILITIES_ROLES, FETCH_UTILITIES_USER,
    FETCH_UTILITIES_SESSIONS, FETCH_UTILITIES_CLASSES,
    FETCH_UTILITIES_SECTIONS, FETCH_UTILITIES_CLASSROOMS,
    FETCH_UTILITIES_PERIODS, FETCH_UTILITIES_SUBJECTS, FETCH_UTILITIES_ALL_SUBJECTS,
    FETCH_UTILITIES_USERS, FETCH_UTILITIES_HODS, FETCH_UTILITIES_STUDENTS, FETCH_UTILITIES_ROUTINES, FETCH_SECTION_ROUTINES,
    FETCH_UTILITIES_SESSION, RESET_UTILITY_ROUTINES, FETCH_SECTION_PERIODS,
    FETCH_UTILITIES_CANDIDATES, FETCH_UTILITIES_MESSAGE_CANDIDATES, FETCH_UTILITIES_COMMENT_CANDIDATES,
    FETCH_UTILITIES_COORDINATED_STUDENTS,
    FETCH_UTILITY_CRITERIA, FETCH_UTILITY_POSITIONS,
} = actions;

const initState = {
    loading: false,
    user: {_id: null},
    roles: [],
    sessions: [],
    criteria: [],
    positions: [],
    classes: [],
    sections: [],
    classRooms: [],
    periods: [],
    sectionPeriods: [],
    subjects: [],
    allSubjects:[],
    users: [],
    hods: [],
    students: [],
    coordinatedStudents: [],
    personalRoutines: {},
    RoutinesAndPeriods: {},
    candidates: [],
    messageCandidates: [],
    commentCandidates: [],
    session: {},
    academicInfo: {},
    studentAcademicInfo: {},
    fileSize: 0,
    fileLength: 0,
};

const UtilitiesReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case LOADING_START:
            return {
                ...state,
                loading: true,
            };
        case LOADING_END:
            return {
                ...state,
                loading: false,
            };
        case FETCH_STUDENT_ACADEMIC_INFO:
            return {
                ...state,
                studentAcademicInfo: data,
            };
        case FETCH_ACADEMIC_INFO:
            return {
                ...state,
                academicInfo: data,
            };
        case FETCH_UTILITIES_ROLES:
            return {
                ...state,
                roles: data,
            };
        case FETCH_UTILITY_CRITERIA:
            return {
                ...state,
                criteria: data,
            };
        case FETCH_UTILITY_POSITIONS:
            return {
                ...state,
                positions: data,
            };
        case FETCH_UTILITIES_USER:
            return {
                ...state,
                user: data,
            };
        case FETCH_UTILITIES_SESSIONS:
            return {
                ...state,
                sessions: data,
            };
        case FETCH_UTILITIES_SESSION:
            return {
                ...state,
                session: data,
            };
        case FETCH_UTILITIES_CLASSES:
            return {
                ...state,
                classes: data,
            };
        case FETCH_UTILITIES_SECTIONS:
            return {
                ...state,
                sections: data,
            };
        case FETCH_UTILITIES_CLASSROOMS:
            return {
                ...state,
                classRooms: data,
            };
        case FETCH_UTILITIES_PERIODS:
            return {
                ...state,
                periods: data,
            };
        case FETCH_SECTION_PERIODS:
            return {
                ...state,
                sectionPeriods: data,
            };
        case FETCH_UTILITIES_SUBJECTS:
            return {
                ...state,
                subjects: data,
            };
        case FETCH_UTILITIES_ALL_SUBJECTS:
            return {
                ...state,
                allSubjects: data,
            };
        case FETCH_FILE_SIZE:
            return {
                ...state,
                fileSize: data,
            };
        case FETCH_FILE_LENGTH:
            return {
                ...state,
                fileLength: data,
            };
        case FETCH_UTILITIES_USERS:
            return {
                ...state,
                users: data,
            };
        case FETCH_UTILITIES_HODS:
            return {
                ...state,
                hods: data,
            };
        case FETCH_UTILITIES_STUDENTS:
            return {
                ...state,
                students: data,
            };
        case FETCH_UTILITIES_COORDINATED_STUDENTS:
            return {
                ...state,
                coordinatedStudents: data,
            };
        case FETCH_UTILITIES_ROUTINES:
            return {
                ...state,
                personalRoutines: data,
            };
        case FETCH_SECTION_ROUTINES:
            return {
                ...state,
                RoutinesAndPeriods: data,
            };
        case FETCH_UTILITIES_CANDIDATES:
            return {
                ...state,
                candidates: data,
            };
        case FETCH_UTILITIES_MESSAGE_CANDIDATES:
            return {
                ...state,
                messageCandidates: data,
            };
        case FETCH_UTILITIES_COMMENT_CANDIDATES:
            return {
                ...state,
                commentCandidates: data,
            };
        case RESET_UTILITY_ROUTINES:
            return {
                ...state,
                routines: initState.routines,
            };
        default:
            return state;
    }
};
export default UtilitiesReducer;
